@charset "utf-8";

/*------------------- Navigations ------------------*/
.e-header-nav{
  box-shadow: 0px 2px 15px 0 rgba(181, 181, 181, 0.1);
  background: #fff;
  /*position: fixed;*/
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.e-profile-img{
  width: 40px;
  height: 40px;
  display: inline-block;
  object-fit: cover;
  cursor: pointer;
}
.e-logo{
  width: 200px;
  margin: 5px 0;
}
.e-nav-item-wrap{
  padding-top: 20px;
}
.e-nav-item-wrap .e-nav-item{
  color: #444444!important;
  font-size: 13px;
  font-weight: 600;
  margin-right: 30px;
}
.e-nav-item-wrap .e-nav-item.e-active{
  font-weight: 600;
  color: #0461D1;
}
.e-nav-user-wrap{
  display: inline-block;
  color: #444444 !important;
  font-size: 14px;
  font-weight: 800;
  padding-right: 20px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/down-arrow.svg");
  background-size: 12px;
}
.e-header-shortname{
  background: blue;
  color: white;
  padding: 8px;
  border-radius: 50%;
  font-size: 16px;
}
.e-nav-user-wrap .e-nav-user{
  width: 23px;
  height: 23px;
  background-color: #1b61b6;
  border-radius: 8px;
  display: inline-block;
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/user-avatar.png");
  position: relative;
  top: 5px;
  margin-right: 8px;
}
.e-logout-dDown-wrap{
  padding: 6px 20px;
  display: inline-block;
  border-radius: 5px;
  position: absolute;
  border: 1px solid #ccc;
  top: 54px;
  right: 14px;
  background: #fff;
  box-shadow: 0px 6.5px 8px 0 rgba(204, 204, 204, 0.2);
  cursor: pointer;
  font-weight: 500;
}
.e-logout-dDown-wrap:hover{
  color: #0b5ecd;
}
.e-logout-dDown-overlay{
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(249, 249, 249, 0.8);
  display: inline-block;
}

/*Login*/
.e-login-outer-wrap{
  background-image: url("../images/login-bg.png");
  background-size: 65%;
  height: 100vh;
  overflow-y: hidden;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}
.e-login-panel{
  box-shadow: 0 0 60px 0 rgba(31, 32, 41, 0.1);
}
.e-login-btn{
  font-size: 14px;
  padding: 8px 20px;
}
.e-login-logo{
  max-width: 100px;
  margin: 20px 0;
}
.e-modal-check-wrap input{
  margin-right: 10px;
  position: relative;
  top: -2px;
}
.e-btn-inactive{
  opacity: .3;
  cursor: not-allowed;
}
/*pagination*/
.e-pagination{
  list-style: none;
}
.e-pagination li{
  display: inline-block;
  margin: 0 8px;
  padding: 3px 10px;
  cursor: pointer;
}
.e-pagination button:disabled {
  opacity: .4;
}
.e-pagination .e-previous-page, .e-pagination .e-next-page{
  padding: 0;
  margin: 0 !important;
}
.e-pagin-prev, .e-pagin-next{
  border: none;
  background-color: #ffffff;
}
.e-pagin-number{
  border: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;

}
.e-pagin-active{
  background: #F0F0F0;
  border: 1px solid #0461D1 !important;
  padding: 3px 9px;
  border-radius: 50%;
}
.e-pagin-active a{
  background-color: #F0F0F0;
}
/*Loaders*/
.e-page-loader{
  width: 24px;
}
.e-page-overlay-loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(255 255 255 / 0.8);
  left: 0;
  top: 0;
  z-index: 99;
  text-align: center;
  padding-top: 250px;
}
.e-page-overlay-loader .e-page-loader{
  width: 200px;
}
.e-min-height-loader{
  min-height: 90vh;
}
/*Alice Blue*/
.e-blog-list-more-txt {
  top: 2px;
  margin-left: 20px;
}
.e-hm-pre-head{
  font-weight: 500;
  color: #797979;
}
.e-hm-banner-wrap h5 span{
  font-size: 31px;
  font-weight: 600;
  color: #969696;
  position: relative;
  top: -4px;
  padding-right: 5px;
  line-height: 0;
}
.e-hm-card-wrap{
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}
.e-hm-card-wrap h4{
  color: #fff !important;
}
.e-hm-offer-tag{
  /*background: #e4e4e4;*/
  color: #000;
  display: inline-block;
  font-weight: 500;
  border-radius: 30px;
  font-size: 12px;
}
.e-hm-card-btn{
  width: 110px;
  padding: 8px;
}
.e-hm-prod-logo{
  width: 90%;
  object-fit: contain;
  position: relative;
  left: -29px;

}
.e-p-btn.e-product {
  width: 100%;
  padding: 6px 10px;
  text-align: center;
}
.e-product-card-wrap h4 {
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  font-weight: 700;
}
/*.e-card-header {
  background: #008AD1;;
}*/
.e-hm-prod-icon{
 /* width: 90%;*/
  object-fit: contain;
  max-height: 120px;
  position: relative;
  left: 92px;
  top: -25px;
}
.e-card-description {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}
.e-card-type-tag{
  color: #0461D1;
  display: inline;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  background: #EBF4FF;
  padding: 5px 10px;
  margin-right: 8px;
  border-radius: 10px;
}
.e-price {
  font-weight: 600;
}
.e-hm-filter-wrap span{
  margin-right: 20px;
  font-size: 18px;
  font-weight: 900;
  color: #adadad;
  transition: all .3s linear;
  cursor: pointer;
}
.e-hm-filter-wrap span.active{
  color: #000;
  border-bottom: 2px solid #239fdb;
}
.e-hm-filter-wrap span:hover{
  color: #2e2e2e;
}
.e-hm-web-link{
  font-size: 18px;
  font-weight: 600;
  color: #25448c;
  transition: all .3s linear;
}
.e-hm-web-link:hover{
  color: #000;
  background: linear-gradient(to right, #000 0%, #ccc 98%);
  background-repeat: no-repeat;
  background-position: left 85%;
  text-decoration: none;
  background-size: 100% 2px;
}
.e-hm-modal-back{
  width: 80px;
  padding: 8px;
  font-size: 14px;
}
.e-hm-modal-back:hover{
  color: #000;
}
.e-color-picker-link{
  font-size: 12px;
  font-weight: 600;
}
.e-admin-card-edit{
  color: #000 !important;
  margin: 0 !important;
  width: 65px;
  display: inline-block;
  text-align: center;
  border: #000 !important;
}
.e-admin-card-edit:hover{
  background: #000 !important;
  color: #fff !important;
}
.e-admin-card-remove{
  color: #fff !important;
  margin: 0 !important;
  background: #2f2f2f !important;
  border: #2f2f2f !important;
  margin-left: 10px !important;
  width: 65px;
  display: inline-block;
  text-align: center;
}
.e-admin-card-remove:hover{
  background: #ffff !important;
  color: #2f2f2f !important;
}



/*Dashboard*/
.e-add-product-btn.e-p-btn {
  padding: 9px 18px;
}
span.e-tabs-item.e-active {
  font-weight: 800;
  color: #444444!important;
  font-size: 18px;
  cursor: pointer;
}

span.e-color-inactive {
  color: rgba(68, 68, 68, 0.27);
   font-weight: 800;
   font-size: 18px;
   cursor: pointer;


}
.e-dash-cardList-wrap h2{
  display: inline-block;
}
.e-dash-search{
  color: #B6B6B6;
  border-radius: 8px;
  border: solid 1px #e7e7e7;
  background-color: #F9F9F9;;
  padding: 8px 20px 8px 40px;
  margin-left: 30px;
  background-position: 15px;
  background-repeat: no-repeat;
  background-image: url("../images/search-icon.svg");
  background-size: 14px;
  /*min-width: 250px;*/
  position: absolute;
  left: 276%;
  width: 100%;
}
.e-dash-search.e-search-subscription {
  position: initial;
  left: 0;
  padding: 8px 20px 8px 32px;
  background-position: 9px;
}
.e-dash-search:focus{
  background-color: #fff;
  border: solid 1px #dedede;
}
.e-dash-search::-webkit-input-placeholder{
  font-weight: 500;
  font-size: 14px;
  color: #c2c2c2;
}
.e-dash-search:-ms-input-placeholder{
  font-weight: 500;
  font-size: 14px;
  color: #c2c2c2;
}
.e-dash-search::placeholder{
  font-weight: 500;
  font-size: 14px;
  color: #c2c2c2;
}
.e-dash-filter-placeholder{
  color: rgba(7, 7, 7, 0.5);
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
}
.e-dash-filter-wrap .css-2b097c-container{
  min-width: 140px;
  display: inline-block;
  margin-right: 20px;
  text-align: left;
}
.e-dash-filter-wrap .css-2b097c-container:last-child{
  margin-right: 60px;
}
.e-dash-filter-wrap .css-2b097c-container .css-yk16xz-control{
  border-radius: 10px;
  /*border: solid 1.5px #ececec !important;*/
  background-color: #ffffff;
}
.css-yk16xz-control {
  padding: 0!important;
}
.css-yk16xz-control{
  border-radius: 5px;
  box-shadow: 0px 6.5px 8px 0 rgba(204, 204, 204, 0.2);
  border: none !important;
  background-color: #ffffff;
  z-index: 0;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}
.css-1uccc91-singleValue, .css-26l3qy-menu{
  z-index: 999 !important;
}
.e-main-content.e-dash-cardList-wrap .css-yk16xz-control,.e-main-content.e-dash-cardList-wrap .css-1pahdxg-control {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #444444;
  min-height: 34px !important;
  /*border: 1px solid #00000020!important;*/
}
.e-modal-select-wrap .css-yk16xz-control{
  border: 1px solid #00000020!important;
  box-shadow: none;
  font-size: 14px !important;
}
.e-main-content .e-modal-select-wrap .css-1uccc91-singleValue{
  font-size: 14px !important;
  font-weight: 500 !important;
}

.e-modal-select-wrap-lg .css-yk16xz-control,
.e-modal-select-wrap-lg .css-yk16xz-control:hover{
  border: 1px solid #00000020!important;
  box-shadow: none;
  font-size: 14px !important;
}
.e-main-content .e-modal-select-wrap-lg .css-1uccc91-singleValue,.e-modal-select-wrap-lg .css-1wa3eu0-placeholder{
  font-size: 14px !important;
  font-weight: 500 !important;
}
.e-modal-select-wrap-lg .css-1hwfws3{
  padding: 13px 15px;
}

.css-yk16xz-control:hover, .css-1pahdxg-control{
  border: none!important;
  /*box-shadow: none !important;*/
 /* border-radius: 10px !important;*/
}
.css-1pahdxg-control:hover {
  border-color: #fff!important;
}
.css-1pahdxg-control:focus {
  border-color: transparent!important;
}
.e-card-rate-wrap{
  display: inline-block;
  color: #fff;
  font-size: 18px;
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 8px 16px;
  font-weight: 500;
  position: absolute;
  right: 30px;
  bottom: 15px;
}
.e-card-rate-star{
  background-repeat: no-repeat;
  background-image: url("../images/star-icon.svg");
  background-size: 14px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 5px;
  background-position: center;
  background-size: contain;
}
.e-noData-img{
  max-width: 200px;
  margin-bottom: 20px;
}
.e-noData-txt{
  font-weight: 500;
}
.e-load-more-wrap {
  display: block;
}
.e-load-more-wrap .e-s-btn{
  color: #000;
  display: inline-block;
  transition: all .3s linear;
}
.e-load-more-wrap .e-s-btn:hover{
  color: #21418a
}
.e-lazy-loader{
  display: inline-block;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-image: url("../images/lazyLoader.gif");
}
.e-mrktplce-usrtype {

  font-size: 12px;
  line-height: 16px;
  background: #EBF4FF;
  border-radius: 100px;
  padding: 4px 8px;

  color: #0461D1;
}

/*Subscription*/

.e-subscription-wrap .DateRangePickerInput .DateRangePickerInput_1 .DateRangePickerInput__withBorder .DateRangePickerInput__withBorder_2{
  width: 200px;
}
.e-subscription-wrap .DateInput .DateInput_1 .DateInput__small .DateInput__small_2{
  width: 89px !important;
}
/*Product Detail*/

.e-prod-banner{
  padding: 130px 0;
}
.e-prod-banner .e-product-logo{
  max-width: 120px;
  max-height: 60px;
  object-fit: contain;
}
.e-prod-banner .e-product-icon{
  max-height: 200px;
}
.e-prod-detail-wrap{
  border-radius: 10px;
  box-shadow: 0px 4.5px 4.4px 2.1px rgba(177, 177, 177, 0.14);
  background-color: #ffffff;
  padding: 40px 50px 50px;
  position: relative;
  top: -120px;
}
.e-product-fav-icon{
  width: 36px;
  height: 36px;
  background-image: url("../images/fav-icon-1.svg");
  display: inline-block;
  cursor: pointer;
  background-size: cover;
  transition: all .3s linear;
}
.e-product-fav-icon:hover{
  background-image: url("../images/fav-icon-2.svg");
  -webkit-transform: scale(.90);
  -ms-transform: scale(.90);
  transform: scale(.90);
}
.e-prod-detail-wrap h1{
  text-align: left;
  color: #000000;
  line-height: 1.67;
  font-size: 24px;
  font-weight: 600;
}
.e-prod-detail-wrap p{
  font-size: 16px;
  font-weight: 300;
  color: #010101;
  line-height: 1.88;
}
.e-product-price-txt{
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.67;
}
.e-product-price-txt b{
  font-weight: 600;
}
#e-confirmModal{
  z-index: 999999;
}
.e-prod-review-head{
  border-top: 2.5px solid #46c212;
}
.e-prod-review-head h6{
  background: #fcfcfc;
  color: #000;
  padding-right: 30px;
  display: inline-block;
  position: absolute;
  top: -12px;
  font-size: 18px;
  font-weight: 500;
}
.e-prod-review-item-wrap{
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.e-prod-review-item-wrap h6{
  display: inline-block;
  color: #000;
  line-height: 1.67;
  font-size: 18px;
  font-weight: 600;
}
.e-review-green-star{
  display: inline-block;
  background-image: url("../images/green-star.svg");
  width: 12px;
  height: 12px;
  background-size: cover;
  top: 1px;
  position: relative;
}
.e-review-star-rate{
  display: inline-block;
  margin-left: 30px;
  font-weight: 600;
  color: #000;
  font-size: 18px;
}
.e-prod-review-item-wrap p{
  color: #000000;
  line-height: 1.88;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
}
.e-prod-review-load-more span{
  color: #000000;
  line-height: 1.67;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  background-image: url("../images/down-arrow.svg");
  background-size: 14px;
  background-position: 100% 65%;
  padding-right: 30px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all .3s linear;
}
.e-prod-review-load-more span:hover{
  color: #239fdb;
  background-image: url("../images/down-arrow-blue.svg");
}
.e-prod-rate-card{
  border-radius: 10px;
  box-shadow: 0px 4.5px 4.4px 2.1px rgba(177, 177, 177, 0.14);
  background-color: #ffffff;
  text-align: center;
  padding: 30px;
}
.e-prod-rate-card .e-product-rated-txt{
  color: #858585;
  line-height: 2.07;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
}
.e-prod-rate-card .e-product-rate{
  color: #000000;
  font-size: 70px;
  font-weight: 600;
  padding-bottom: 20px;
}
.e-prod-rate-card .e-review-green-star{
  width: 20px;
  height: 20px;
}
.e-prod-rate-card .e-product-rate-link{
  color: #111111;
  line-height: 1.68;
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  transition: all .3s linear;
}
.e-prod-rate-card .e-product-rate-link:hover{
  color: #239fdb;
}
.e-product-support-icon{
  background-image: url("../images/support-icon.svg");
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: cover;
}
.e-product-support-wrap p{
  color: #3399cc;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.e-product-support-wrap{
  border-radius: 10px;
  box-shadow: 0px 4.5px 4.4px 2.1px rgba(177, 177, 177, 0.14);
  background-color: #ffffff;
  padding: 30px;
}
.e-product-review-less-txt{
  font-weight: 500;
  color: #239fdb;
  font-size: 14px;
  position: relative;
  left: 0;
  bottom: -5px;
  cursor: pointer;
  transition: all .3s linear;
}
.e-product-review-more-txt:hover, .e-product-review-less-txt:hover{
  color: #000;
}
.e-subscribe-prod-card-wrap{
  min-height: 345px;
}
.e-replay-link{
  padding-left: 20px;
  background-image: url("../images/replay-icon.png");
  background-size: 14px;
  background-position: 0;
  background-repeat: no-repeat;
  margin-left: 30px;
  color: #0b5ecd;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s linear;
}
.e-replay-link:hover{
  color: #000;
  background-image: url("../images/replay-icon-black.svg");
}
.e-remove-link{
  padding-left: 20px;
  background-image: url("../images/remove-icon.svg");
  background-size: 14px;
  background-position: 0;
  background-repeat: no-repeat;
  margin-left: 30px;
  color: #6B6B6C;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all .3s linear;
}
.e-remove-link:hover{
  color: #0b5ecd;
  background-image: url("../images/remove-icon-blue.svg");
}
.e-prod-detail-btn-wrap{
  border-left: 1px solid #ccc;
}
.e-prod-detail-btn-wrap span{
  border-radius: 10px;
  background-color: #1b61b6;
  padding: 10px 22px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  transition: all .4s linear;
  margin-bottom: 20px;
  margin-left: 30px;
  width: 95px;
  text-align: center;
}
.e-r-btn{
  transition: ease-in-out 0.5s;
}
.e-marketplace-allcard-sec .e-r-btn:hover{
  color: #fff;
  background: red;
  border: 1.5px solid red;
}
.e-prod-detail-btn-wrap .e-s-btn{
  color: #000;
  background-color: #fff;
  border: 1.5px solid #2e2e2e;
  padding: 8px 20px;
}
.e-prod-detail-btn-wrap .e-s-btn:hover{
  color: #fff;
  background-color: #000;
}

.e-prod-detail-btn-wrap .e-r-btn{
  color: #fb6464;
  background-color: #fff;
  border: 1.5px solid #fb6464;
  padding: 8px 20px;
}
.e-prod-detail-btn-wrap .e-r-btn:hover{
  color: #fff;
  background-color: #2e2e2e;
  border: 1.5px solid #2e2e2e;
}
.e-reply-wrap{
  padding-left: 30px;
  padding-top: 20px;
}
.e-reply-owner{
  font-weight: 600;
  font-size: 14px;
  color: #000;
}
.e-reply-wrap .e-replay-link, .e-reply-wrap .e-remove-link{
  font-size: 12px;
  padding-left: 18px;
  background-size: 12px;
}


/*Modals*/
.e-modal-wrap{
  z-index: 9999;
}
.e-modal-wrap button{
  position: absolute;
  right: 30px;
  top: 15px;
  font-weight: 300;
  font-size: 32px;
  z-index: 99999;
}
.e-modal-wrap h5{
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}
.e-modal-addProduct{
  max-width: 800px;
}
.e-modal-wrap .css-2b097c-container{
  width: 100%;
  display: inline-block;
  margin-right: 20px;
  text-align: left;
}
.e-modal-wrap  .css-2b097c-container:last-child{
  margin-right: 60px;
}
.e-modal-wrap  .css-2b097c-container .css-yk16xz-control{
  border-radius: 5px;
  border: 1px solid #00000020 !important;
  background-color: #ffffff;
  box-shadow: none;
  min-height: 40px;
}
.e-modal-wrap  .css-1hwfws3{
  padding-left: 15px;

}
.e-modal-wrap .css-yk16xz-control:hover, .e-modal-wrap .css-1pahdxg-control{
  border: 1px solid #000 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}
.e-modal-wrap .css-1uccc91-singleValue, .e-modal-wrap .css-26l3qy-menu{
  font-size: 14px !important;
  font-weight: 500 !important;
  z-index: 999 !important;
}
.e-modal-wrap .e-upload-wrap .e-upload-btn{
  border-radius: 5px;
  box-shadow: 0px 1.5px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c4c4c4;
  background-color: #fafafa;
  height: 110px;
  text-align: center;
  padding: 34px 5px;
  color: #6c6969;
  font-size: 14px;
  font-weight: 500;
}
.e-addProduct-btn-wrap{
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.e-color-preview-wrap{
  color: #000;
  font-weight: 500;
}
.e-color-preview{
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.e-uploader-loader{
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: cover;
  background-image: url("../images/upload-loader.gif");
}
.e-preview-img{
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: inline-block;
  top: -5px;
  position: relative;
}
.e-modal-wrap .css-tj5bde-Svg{
  display: none;
}
.e-video-success{
  background-size: 30px;
  background-position: center 38px;
  background-repeat: no-repeat;
  font-size: 13px;
  border-radius: 5px;
  border: 2px dashed #3a9243;
  padding: 56px 0;
  text-align: center;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  background-color: #dbf7de;
  font-weight: 600;
  color: #0e6319;
  display: block;
}
.e-valid-type-select .css-2b097c-container{
  width: 80px;
  background: #f1f1f1;
  position: absolute;
  right: 19px;
  top: 12px;
}
.e-valid-type-select .css-yk16xz-control{
  background-color: hsl(0deg 0% 88%);
}
.e-trial-type-wrap .css-2b097c-container{
  top: 8px;
}
.e-video-thumbnail-upload{

}

/*Vendors*/
.e-vendors-sideMenu{
  background: #fff;
}
.e-vendors-sideMenu-item{
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all .3s linear;
  cursor: pointer;
  margin-bottom: 20px;
  color: #444444;
}
.e-vendors-sideMenu-item:hover{
  color: #239fdb;
  background: #f9f9f9;
}
.e-vendors-sideMenu-item.e-active{
  color: #fff;
  background: #0461D1;
}
/*.e-vendors-sideMenu-item:last-child{
  border-bottom: none;
  border-radius: 0px 0px 10px 10px;
}
.e-vendors-sideMenu-item:first-child{
  border-radius: 10px 10px 0 0;
}*/
.e-invite-vendor-wrap{
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  background-color: #ffffff;
  padding: 30px;
}
.e-invite-vendor-wrap .e-p-btn{
  border-radius: 5px;
  padding: 12px 16px;
  display: inline-block;
}
.e-request-item{
 /* border: solid 1px #ececec;
  background-color: #f7f7f7;*/
  padding: 15px;
  margin-bottom: 30px;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
}
.e-request-accept {
  color: #13D726;
  font-weight: 600;
  transition: ease-in-out 0.5;
}
.e-request-accept:hover{
  color: #499d0d;
  cursor: pointer;
}
.e-request-reject {
  color: #F85959;
  font-weight: 600;
}
.e-request-reject:hover{
  color: #d82e70;
  cursor: pointer;
}

.e-request-placeholder{
  padding: 15px 0px;
  display: inline-block;
  border-radius: 50%;
  background: blue;
}
.e-partnered-wrap h2 {
  /*font-weight: 800;*/
  font-size: 18px;
  line-height: 23px;
  color: #444444;
}
.media-body h5{
  font-size: 14px;
  font-weight: 800;
  color: #000;
}
.media-body p{
  color: #000000;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 0;
}
.e-request-item .e-p-btn{
  padding: 6px 14px ;
}
.e-request-item .e-s-btn{
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  transition: all .4s linear;
  color: #000;
  background-color: #fff;
  border: 1px solid #2e2e2e;
  padding: 5px 12px;
  border-radius: 5px;
}
.e-request-item .e-s-btn:hover{
  color: #fff;
  background-color: #000;
}
/*.e-request-item-wrap{
  padding: 30px 30px 0 30px;
}*/
.e-partnered-wrap .e-request-item{
  background: #fff;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  border: none;
}
.e-invite-vendor-wrap .e-s-btn.e-sending{
  color: blue;
  border-bottom: none;
}
.e-invite-vendor-wrap .e-s-btn.e-failed{
  color: red;
  border-bottom: none;
}
.e-invite-vendor-wrap .e-s-btn.e-success{
  color: green;
  border-bottom: none;
}




/*Vendor Detail*/
.e-add-vendor.e-p-btn {
  padding: 12px 18px;
}
.e-vendorDetail-back{
  color: #0b5ecd;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all .4s linear;
  padding-left: 17px;
  background-size: 10px;
  background-image: url("../images/back-icon-blue.svg");
  background-repeat: no-repeat;
  background-position: 0 4px;
}
.e-vendorDetail-back:hover{
  color: #000;
  background-image: url("../images/back-icon-black.svg");
}
.e-vendorDetail-wrap{
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  background-color: #ffffff;
}
.e-vendorDetail-btn-wrap{
  padding-top: 30px;
}
.e-vendorDetail-btn-wrap .e-p-btn{
  border-radius: 10px;
  border: solid 1.5px #0b5ecd;
  background-color: #ffffff;
  color: #0b5ecd;
  font-size: 14px;
  font-weight: bold;
}
.e-vendorDetail-btn-wrap .e-p-btn:hover{
  background: #0b5ecd;
  color: #fff;
}
.e-vendorDetail-btn-wrap .e-r-btn{
  border-radius: 10px;
  border: solid 1.5px #fb6464;
  background-color: #ffffff;
  color: #fb6464;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 16px;
  transition: all .3s linear;
  cursor: pointer;
}
.e-vendorDetail-btn-wrap .e-r-btn:hover{
  background: #fb6464;
  color: #fff;
}
.e-vendorDetail-btn-wrap .e-linktext{
  color: #0b5ecd;
  font-weight: 500;
}
.e-vendorDetail-btn-wrap .e-linktext:hover{
  color: #000;
}
.e-enable-btn{
  color: #0461D1;
  background: none;
  border: none;
  font-weight: 600;
  transition: ease 0.5s;
}
.e-enable-btn:hover{
  color: #0D31B1;
}
/*Support Chat*/
.e-conversation-wrap{
  border-radius: 10px;
  border: solid 1px #ececec;
  background-color: #ffffff;
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: sticky;
}
.e-conversation-wrap h6 {
  font-weight: 800;
  font-size: 18px;
  color: #444444;
}
.e-conversation-head{
  border-radius: 10px 10px 0 0 ;
  background-color: #ffffff;
  padding: 15px 15px 30px;
  border-bottom: 1px solid  #ececec;
}
.e-conversation-select{
  position: absolute;
  right: 30px;
  top: 10px;
}
.e-conversation-select .css-2b097c-container {
  background: #FFFFFF;
  border: 1px solid #F8F8F8;
  box-sizing: border-box;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;

}
.e-conversation-select .css-1hwfws3{
  padding: 2px 6px;
  min-width: 50px;
}
.e-conversation-select .css-yk16xz-control{
  min-height: 33px;
  box-shadow: none;
  z-index: 0;
}
.e-conversation-select .css-tlfecz-indicatorContainer, .e-conversation-select .css-1gtu0rj-indicatorContainer{
  padding: 0px 4px;
}
.css-w8afj7-Input{
  padding: 0 !important;
}
.e-conversation-select  .css-1uccc91-singleValue, .css-26l3qy-menu{
  font-size: 14px !important;
  font-weight: 500 !important;
  z-index: 999 !important;
  color: #444444;
}
.e-conversation-select  .css-yk16xz-control:hover, .e-conversation-select  .css-1pahdxg-control, .e-vendor-filter-wrap .css-yk16xz-control:hover, .e-vendor-filter-wrap .css-1pahdxg-control{
  /*border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;*/
}
.e-conversation-select .css-1pahdxg-control{
  min-height: 30px !important;
  background: #FFFFFF;
  border: 1px solid #F8F8F8;
  box-sizing: border-box;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;

}
.e-conversation-item{
  padding: 8px 15px 8px;
  border-bottom: 1px solid #ececec;
  transition: all .3s linear;
  cursor: pointer;
}
.e-conversation-item .e-request-placeholder{
  width: 23px;
  height: 31px;
  font-size: 10px;
  padding-top: 9px;
  color: #ffffff;
  text-align: center;
  position: relative;
  top: 5px;
}
.e-conversation-item .media-body h5{
  font-size: 13px;
  margin-bottom: 0px !important;
  font-weight: 700;
  color: #444444;
}
.e-conversation-item .media-body p{
  margin-bottom: 0 !important;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}
.e-conversation-item.e-active, .e-conversation-item:hover{
  background-color: #EBF4FF;
}
.e-unread-dot{
  width: 10px;
  height: 10px;
  background: #709fde;
  border-radius: 50%;
  position: relative;
  top: 15px;
  display: none;
}
.e-conversation-item.e-unseen .e-unread-dot{
  display: inline-block;
}
.e-chat-placeholder-wrap{
  min-height: 95px !important;
}
.e-chat-animated-bg{
  height: 65px !important;
}
.e-chat-wrap{
  border-radius: 10px;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  background-color: #ffffff;
  height: 80vh;
  margin-bottom: 20px;
}
.e-chat-head-wrap{
  /*border-bottom: 1px solid #ececec;*/
  padding: 15px 15px 17px;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}
.e-chat-head-wrap .e-chat-head{
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}
.e-chat-head-wrap .e-chat-head b{
  color: #000000;
}
.e-chat-more-icon{
  background-image: url("../images/chat-clse.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
}
.e-chat-more-dropdown{
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  position: absolute;
  right: 40px;
  top: 55px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  z-index: 9;
  cursor: pointer;
  transition: all .3s linear;
}
.e-chat-more-dropdown:hover{
  color: #0b5ecd;
}
.e-chat-more-icon.e-close{
  background-image: url("../images/close-icon.svg");
}
.e-chat-body{
  padding: 22px 15px 30px;
  height: 61vh;
  overflow-y: scroll;
  display: block;
  overflow-x: hidden;
}
.e-chat-item{
  position: relative;
  margin-bottom: 5px;
}
.e-chat-pic{
  width: 30px;
  height: 30px;
  background-image: linear-gradient(to top, #3c80da, #0065d2);
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding-top: 5px;
  position: relative;
  top: -8px;
  margin-right: 15px;
  opacity: 0;
  text-transform: uppercase;
}
.e-chat-message{
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  padding: 12px;
  max-width: 80%;
  background: #0461D1;
  border-radius: 8px;
  color: #FFFFFF;
}
.e-chat-user .e-chat-message{
  background: #EAEAEA;
  border-radius: 8px;
  color: #444444;
}
.e-chat-admin .e-chat-pic{
  top: 15px;
  margin-left: 15px;
  margin-right: 0;
  background: #505050;
  background-image: url("../images/user-avatar.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
}
.e-chat-user + .e-chat-admin .e-chat-pic, .e-chat-admin + .e-chat-user .e-chat-pic, .e-chat-admin:first-child .e-chat-pic, .e-chat-user:first-child .e-chat-pic{
  opacity: 1;
}
.e-chat-admin + .e-chat-user{
  margin-top: 20px;
}
.e-chat-user + .e-chat-admin{
  margin-top: 20px;
}

.e-chat-footer{
 /* padding: 15px 30px 30px;*/
 padding: 10px 15px 10px;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
  margin-top: 35px;

}
.e-chat-footer input{
  width: 100%;
  border-radius: 10px;
  background: #F0F0F0;
  padding: 9px 15px;
  border: none;
  outline: 0;
  background-image: url("../images/chat-send.svg");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 97%;
  cursor: pointer;
}
.e-chat-footer input::placeholder {
  font-size: 14px;
  line-height: 18px;
  color: #B6B6B6;
  font-weight: 300;
}
.e-chat-time{
  font-size: 10px;
  color: #2e2e2e;
  display: block;
  padding-top: 5px;
}
.e-chat-user .e-chat-time{
  margin-left: 45px;
}
.e-chat-admin .e-chat-time{
  position: absolute;
  bottom: -20px;
  right: 45px;
}
.e-closed-txt{
  font-size: 14px;
  font-weight: 600;
  background: #f9f9f9;
  text-align: center;
  padding: 15px;
}


/* FAQ Page*/
.e-faq-body{
  background: #fff;
  padding: 30px 15px;
}
.e-faq-wrap .e-conversation-head{
  padding: 30px;
}
.e-faq-item{
  border-bottom: 1px solid #f1f1f1;
  margin-top: 30px;
}
.e-faq-item h6{
  font-size: 16px;
  padding-bottom: 10px;
}
.e-faq-item p{
  font-size: 16px;
  padding-right: 80px;
  white-space: pre-wrap;
}
.e-faq-item:first-child{
  margin-top: 0 !important;
}
.e-faq-item:last-child{
  border-bottom: none;
}
.e-faq-brn-wrap span{
  display: inline-block;
  margin-bottom: 15px;
}
.e-faq-brn-wrap .e-p-btn{
  padding: 6px 14px;
}
.e-faq-brn-wrap .e-s-btn{
  padding: 5px 14px;
  border-radius: 10px;
  border: solid 1.5px #fb6464;
  background-color: #ffffff;
  color: #fb6464;
  transition: all .4s linear;
}
.e-faq-brn-wrap .e-s-btn:hover{
  color: #fff;
  border: solid 1.5px #000;
  background-color: #000;
}


.e-p-ellipsis, .e-p-ellipsis-2, .e-p-ellipsis-3{
  display: block;
  display: -webkit-box;
  max-width: 100%;
  line-height: 1.55;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-p-ellipsis-2{
  -webkit-line-clamp: 2;
}
.e-p-ellipsis-3{
  -webkit-line-clamp: 3;
}

/*Subscription list*/
.e-subscription-table-wrap{
  overflow-x: scroll;
  width: 100%;
  display: inline-block;
}
.e-subscription-table-wrap th, .e-subscription-table-wrap td{
  min-width: 130px !important;
}
.e-filter-wrap label{
 /* font-size: 14px;*/
  font-weight: 400;
}
.e-subscription-table-wrap th{
  font-size: 14px;
}
.e-subscription-table-wrap td.e-s-text{
  font-size: 12px;
}
table::-webkit-scrollbar-thumb {
  background: #dee2e6;
  opacity: 0;
}
table::-webkit-scrollbar {
  width: 40px !important;
}
table::-webkit-scrollbar-track {
  background: #dee2e6;
}
.e-subscription-table-wrap .css-1uccc91-singleValue{
  font-size: 12px !important;
}
.e-subscription-table-wrap .css-yk16xz-control, .e-subscription-table-wrap .css-1pahdxg-control{
  min-height: 32px !important;
}
.e-subscription-table-wrap .css-26l3qy-menu{
  z-index: 999 !important;
  position: absolute;
}
.e-subscription-table-wrap .css-yk16xz-control{
  z-index: 1;
}
.e-subscription-table-wrap .css-tlfecz-indicatorContainer{
  z-index: 0 2px;
  font-weight: 200;
}
.csv-reader-input label{
  width: 100px;
  height: 100px;
  border: 1px dotted #ccc;
  padding: 28px 5px 5px;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}
.csv-reader-input label:hover{
  background: #ccc;
}
.css-1uccc91-singleValue, .css-26l3qy-menu {
  font-size: 12px !important;
  font-weight: 300 !important;;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}
.table.e-subscription-table-wrap.table-striped .e-s-btn {
  color: #0461D1;
  font-size: 14px;
  font-weight: 600;
  border-bottom: none;
  cursor: pointer;
  transition: all .2s ease;
}
.table.e-subscription-table-wrap.table-striped.e-payout-table {
  display: inline-table;
}
.table.e-subscription-table-wrap.table-striped.e-payout-table .table td, .table th {
    padding: 1.25rem;
    vertical-align: top;
     border-top: none;
}
.e-payout-btn.e-p-btn {
  padding: 8px 16px;
  border-radius: 8px;
}


/*Dashboard list*/
.e-main-content.e-subscription-wrap .css-1uccc91-singleValue, .css-26l3qy-menu  {
  font-weight: 500!important;
  font-size: 14px!important;
  color: #444444;
}
.e-filter-wrap label {
  font-size: 12px;
  color: #6A707E;
}
.e-dash-item-wrap{
  background: #fff;
  padding: 15px;

  background: #fff;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
  border: none;
  border-radius: 5px;
}
.e-dash-item-wrap b{
  font-weight: 500;
}
.CalendarDay__selected_span{
  background: rgba(10, 94, 205, 0.84) !important;
  border: 1px double rgba(53, 112, 189, 0.6) !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
  background: #095ecd !important;
  border: 1px double #095ecd !important;
}
.DateInput_input__focused{
  border-bottom: 2px solid #095ecd !important;
}
.DateInput_input__small{
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 7px 3px 5px !important;
}
.DateRangePickerInput__withBorder{
  border: none !important;
  padding: 5px;
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.14);
}
.SingleDatePickerInput input{
  width: 97px;
 /* min-width: 120px;*/
  border-radius: 6px;
}
.SingleDatePickerInput__withBorder{
  border: none !important;
}
.DateInput__small{
  width: 69px !important;
  border-radius: 6px;
  border: 0px solid #ccc;
  padding: 5px 0px !important;
}
.DateInput_input {
    color: #484848;
    background-color: #fff;
    width: 93%;
    padding: 10px 0px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
}
.SingleDatePickerInput .DateInput_input__focused{
  border-bottom: none !important;
}
.e-subscription-wrap .DateRangePickerInput__withBorder{
  padding: 0 5px;
}
.e-subscription-wrap .DateInput__small{
  padding: 4px 0 !important;
  width: 89px !important;
}
.DateRangePicker_picker{
  z-index: 2 !important;
}
.e-edit-lead-modal .SingleDatePickerInput__withBorder{
  box-shadow: 0px 7.5px 11.7px 1.8px rgba(169, 162, 162, 0.24) !important;
}
.e-edit-lead-modal .SingleDatePickerInput input{
  width: 430px;
  height: 50px;
}
.e-subscription-table-wrap td.e-table-long-txt{
  min-width: 300px !important;
}
.e-subscription-table-wrap th.e-small-column, .e-subscription-table-wrap td.e-small-column{
  min-width: 80px !important;
}
.css-yk16xz-control {
  padding: 2px 0px 6px;
}

.container-override{
  margin: 20px;
  padding: 20px;
  max-width: 95vw !important;
}
.container-override td {
  font-size: 14px;
}
.e-search-input-override {
  background: transparent;
  /*width: 140px;*/
  position: absolute;
  display: block;
  /*right: 15px;*/
  /*top: -30px;*/
  border-bottom: 0;
  padding-bottom: 6px;
  letter-spacing: .01em;
  background-position: 0 29px;
  background-repeat: no-repeat;
  background-size: 14px;
  padding-left: 25px;
  background-image: url(/static/media/search-icon.e23f03a4.svg);
  font-size: 14px;
}
.min-width-td{
  min-width: 200px;
}


.e-subscription-wrap h4 {
  font-weight: 700;
}
.table.e-subscription-table-wrap.table-striped thead {
  background-color: #E9E9E9;
}
.table.e-subscription-table-wrap.table-striped thead th {
  font-size: 13px;
  font-weight: 700;
   color: #444444;
}
.table.e-subscription-table-wrap.table-striped tbody td {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
}
.table.e-subscription-table-wrap.table-striped tbody td.e-subscription-product {
  color: #0461D1;
}

.e-vendors-sideMenu.e-sub-admin {
  margin-bottom: 20px;
}
.e-dash-item-wrap b {
    font-weight: 400;
    font-size: 14px;
    color: #444444;
}
.e-dash-item-wrap {
  border-radius: 8px;
}
.DateRangePickerInput__withBorder {
    padding: 0px 5px!important;
}
.e-lead-status {
  font-weight: 800;
  font-size: 16px;
  color: #444444;
}
.e-leads {
  font-weight: 600;
  font-size: 14px;
  color: #444444;
  opacity: 0.9;
}
.e-leads label {
  font-size: 12px;
  font-weight: 200;
}
.e-leads h4 {
  font-size: 18px;
  font-weight: 700;
}
.e-leads h6 {
  font-size: 14px;
  font-weight: 700;
}
.e-product-traders {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
  background: #EBF4FF;
  padding: 4px 8px;
  border-radius: 100px;
}
.table.e-subscription-table-wrap.table-striped.e-payout-table.e-referral .table td, .table th {
    padding: 1rem;
}
.css-1wa3eu0-placeholder{
  font-size: 12px;
  font-weight: 500;
  color: #444444!important;
}
/*-----sub-admin---------*/
.e-add-sub-admin h2{
  color: #444444;
  font-weight: 800;
}
.e-btn-sub-admin.e-p-btn {
  padding: 13px 15px;
}

/*-------------Modal----------------*/
.e-modal-border {
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 30px;
}
.e-login h5, .e-login1 h5, .e-login2 h5, .e-login3 h5  {
  font-size: 24px;
  font-weight: 700;
  color: #444444;
  display: inline-block;
}
.e-c-brown {
  height: 13%!important;
}
.e-add-product-modal .css-12jo7m5{
  line-height: 150%;
}
.e-product-card-wrap {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
  padding: 0px 15px;
  min-height: 508px;
}
.e-m-bottom-20 {
  height: 5%;
}
.e-add-product-desc {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background: #EBF4FF;
  border-radius: 100px;
  padding: 8px 16px;
  color: #444444;
}
.e-add-product-modal .css-1n7v3ny-option, .e-add-product-modal  .css-yt9ioa-option{
  padding: 18px 12px;
}
.e-login.e-add-product-modal label,.e-login1.e-add-product-modal label,.e-login2.e-add-product-modal label,.e-login3.e-add-product-modal label{
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #444444;
}
.ev-input-content-wrap .ev-input-contentbox label {
  left: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #999999;
}
.e-ad-dotted-border {
  background: #F6FBFF;
border: 1px dashed #EAEAEA;
box-sizing: border-box;
border-radius: 4px;
}
.e-add-logo {
  margin: 32px 50px;
}
.e-add-illustration {
  margin: 43px 32px;
}
.e-choose-color {
  border-radius: 4px;
  width: 48px;
  height: 48px;
}
.e-upcoming{
  font-weight: 500;
font-size: 14px;
line-height: 20px;
}
.custom-control.custom-checkbox label {
  letter-spacing: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  padding-right: 42px;
}
.e-login,.e-login1,.e-login2,.e-login3 , .e-edit-blog, .e-edit-video, .e-edit-webinar, .e-edit-faq,.e-edit-bank{
  right: -500vW;
  width: 120vw !important;
  height: 100%;
/*  overflow: hidden;*/
  position: fixed;
  background-color: #ffffff;
  transition: ease-in-out ;
  z-index: 10;
  top: 0;
}
.e-login-fly{
  height: 100%;
  top: 0;
  right: 0;
  transition: ease-in-out 1s;
  z-index: 10;
  overflow-y: scroll;
  z-index: 9999;
}
.e-login-overlay,.e-login-overlay1,.e-login-overlay2,.e-login-overlay3{
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all ease 1s;
  z-index: 999;

}
.e-login-closebtn{
  background-color: #ffffff;
  outline: none;
  border: none;
  position: relative;
  top: 65px;
}
.e-login-closebtn:focus{
  outline: none;

}
.SingleDatePickerInput .DateInput_input{
  border: .5px solid #00000020;
}
.SingleDatePickerInput input {
  width: 600px;
  height: 45px;
}
.SingleDatePickerInput input::placeholder{
  padding-left: 10px;
}
.e-upload-btn {
  background-size: 30px;
  background-position: center 38px;
  background-image: url("../images/upload-logo.svg");
  background-repeat: no-repeat;
  font-size: 13px;
  border-radius: 5px;
  border: 2px dashed #ccc;
  padding: 70px 0px 40px;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  background-color: #F6FBFF;
  font-weight: 400;
  color: #999999;
  display: block;
}
.e-upload-btn:hover {
  color: #0461D1;
  background-image: url("../images/upload-logo.svg");
  border: 2px dashed #0461D1;
  background-color: #f9f9f9;
}
.e-upload-btn.e-store.e-video {
  background-size: 30px;
  background-position: center 40px;
  background-image: url("../images/video.svg");
  background-repeat: no-repeat;
  font-size: 13px;
  border-radius: 5px;
  border: 2px dashed #ccc;
  padding: 70px 0px 40px;
  display: block;
  display: block;
  transition: all .3s ease;
  cursor: pointer;
  background-color: #F6FBFF;
  font-weight: 600;
  color: #999999;
}
.e-upload-btn.e-store.e-video:hover {
   background-image: url("../images/video.svg");
   color: #0461D1;
    border: 2px dashed #0461D1;
    background-color: #f9f9f9;
}
.e-subscription {
  background: #F9F9F9;
  padding-left: 0!important;
  padding-right: 0!important;
}
.e-add-video {
  margin: 55px 112px;
}
.e-add-product-modal .e-add-product-link a {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #0461D1;
}
.e-add-product-modal .css-1uccc91-singleValue{
  overflow: initial;
}
.e-login1 .MuiSvgIcon-root, .e-edit-webinar .MuiSvgIcon-root{
  width: 20px !important;
}
.MuiRadio-colorSecondary.Mui-checked, .MuiRadio-colorSecondary.Mui-checked{
  color: #0461D1 !important;
}
.e-login1 .MuiIconButton-label{
  margin-left: -10px;
}
.e-save.e-p-btn {
  padding: 10px 25px;
}
.e-example {
  position: absolute;
  top: 82px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}
.e-login1 .rc-time-picker-input, .e-edit-webinar .rc-time-picker-input{
  font-size: 14px;
  padding: 22px 11px;
  font-size: 14px;
}
.rc-time-picker-panel{
  z-index: 9999 !important;
}
.rc-time-picker-clear{
  top: 11px!important;
}
.e-login1 .e-time-picker, .e-edit-webinar .e-time-picker{
  width: 100%;
}
.e-login1 .DateInput, .e-edit-webinar .DateInput {
  width: 100%;
}
.e-login1 .SingleDatePickerInput input, .e-edit-webinar .SingleDatePickerInput input{
  width: 100%;
}
.e-tips {
  position: relative;
    top: 74px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #444444;
}
.e-product-purchase {
  font-size: 14px;
  font-weight: 500;
}

.custom-control.custom-checkbox label.e-font-14 {
  font-size: 14px;
}
.e-on-date .SingleDatePickerInput input {
    width: 214px;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}
.custom-control-label::before {
    border: #444444 solid 1px;
}
.e-img {
  height: 130px;
}



/*product detail*/
.e-mrktplce-usrtype{
  background: #EBF4FF;
  border-radius: 100px;
  font-weight: 500!important;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
  padding: 5px 8px;
}
.e-edit-details.e-p-btn {
  padding: 8px 12px;
  border-radius: 4px;
}
.e-dd-faq.e-p-btn {
  padding: 7px 7px;
  background: #F6FBFF;
  border: 1px solid #0461D1;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0461D1;
}
.e-marketDetails-header-sec{
  background: #F7FBFF;
  padding: 25px 0px 20px 0px;
}
.e-marketDetails-link, .e-marketDetails-link-color{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss03' on, 'ss01' on;

}
.e-mrktdtls-review-span{
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
}
.e-mrktdtls-review-span:hover{
  cursor: pointer;
}
.e-marketDetails-link{
  color: #0461D1;
}
.e-marketDetails-link-color{
  color: black;
  opacity: 0.8;
}
.e-marketDetails-link-color{
  opacity: 0.50;
}
.e-marketDetails-link:hover{
  color: #444444;
  text-decoration: none;
}
.e-marketDetails-link-color:hover{
  cursor: default;
  text-decoration: none;
  color: black;
  opacity: .5;
}
.e-smallcase-banner-img{
  margin-right: -24px;
}
.e-marketDetails-h5{
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  font-weight: 700;
  margin-top: 35px;
}
.e-marketDetails-h4{
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: #444444;
}
.e-marketDetails-review{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0461D1;
  vertical-align: sub;
  transition: ease-in-out  0.5s;
}
.e-marketDetails-review:hover{
  cursor: pointer;
  color: #0C3E90;

}
.e-marketDetails-review-img{
  height: 24px;
  border-right: 1px solid #444444;
  padding-right: 20px;
}
.e-marketDetails-p{
  font-size: 16px;
  line-height: 21px;
  color: #444444;
  font-weight: 400;
}
.e-marketDetails-banner-sec{
  padding: 0px 0px 100px 0px;
}
.e-marketDetails-card-sec{
  padding-bottom: 100px;
}
.e-mrktplc-playbanner-sec{
  padding: 90px 0px 200px 0px;
}
.e-mrktplc-rule{
  width: 32px;
  height: 4px;
  background-color: #0461D1;
  margin-left: 0;
}
.e-card-head{
  background:linear-gradient(180deg,  rgba(255, 255, 255, 0) 32.29%, rgba(0, 0, 0, 0.48) 100%   );
  border-radius: 8px 8px 0px 0px;
}
.e-sensi-hed {
  background-color:  #006DE6 !important;
}
.e-abcard-hed{
}
.e-optimum-hed{
  background-color: #DDD6FF !important;
}
.e-marketDetails-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}
.card-logo{
  max-height: 30px;
  object-fit: contain;
  position: relative;
  z-index: 9;
}
.card-illustration{
  position: absolute;
  z-index: 9;
  max-height: 75px;
  right: 0;
}
.e-prcard-rating{
  position: absolute;
  top: 110px;
  left: 20px;
}
.e-prcard-review-count{
  font-size: 12px;
  color: white;
  font-weight: 200;
}
.e-card-head{
  min-height: 140px;
}
.e-req-accept:hover, .e-req-reject:hover{
  cursor: pointer;
}
.e-mrktplc-card-title{
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
  overflow: hidden;
  height: 40px;
  width: 320px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-mrktplc-card-text{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  overflow: hidden;
  height: 40px;
  width: 320px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-mrktplc-product-plan{
  font-size: 12px;
}
.e-prdt-free-grn{
  color: #5ce36a;
}
.e-mrktplc-card-p{
  margin-block-end: auto;
}
.e-marketDetails-card .e-primary-blue-btn{
  padding: 8px 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}
.e-marketDetails-card .e-primary-blue-btn:hover{
  background: #2756A2;
}

/*.e-marketDetails-card .e-primary-otln-btn{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  padding: 7px 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #0461D1;
  transition: ease-in-out 0.8s;
}
.e-marketDetails-card .e-primary-otln-btn:hover{
  background-color: #0461D1;
  color: #FFFFFF;
}*/
/*.e-mrktplce-usrtype{
  background: #EBF4FF;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
  padding: 5px 8px;
}
.e-filter-img{
  width: 30px;
  height: 30px;
}
.e-filter-btn{
  background-color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0461D1;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: none;
}
.e-market-fil{
  right: -500vW;
  width: 60vW !important;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background-color: #ffffff;
  transition: ease-in-out 1s;
  z-index: 10;
  top: 0;
  overflow-y: scroll;
}*/


/*Market place details product details tab*/
.e-product-wrap .css-yk16xz-control {
  background: #EEF6FF;
  border: 1px solid #78B4FD!important;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 10px;
}
.e-ac-direction .e-form-rule{
  top: 0px !important;
}
.e-product-wrap .css-1wa3eu0-placeholder {
  font-weight: 600;
  font-size: 14px;
}
.e-prdtdtls-card .e-form-rule{
  top: 8px;
  left: 30px;
}
.e-prod-detail-illustration{
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}
.e-productdetls-about-smallcase .e-form-rule {
   position: relative;
   margin: 0;
   left: 0;
 }
.e-smallcase-bnft .e-form-rule {
    position: relative;
    margin: 0;
    left: 0;
}
.e-form-rule {
    background: #0461D1;
    width: 30px;
    height: 4px;
    position: absolute;
    /*top: 65px;*/
    left: 15px;
}
.e-img{
  height: 353px;
  margin-top: 20px;
}
.e-img-thum{
  width: 160px;
  height: 134px;
  border-radius: 8px;
}
.e-img-thum:hover{
  cursor: pointer;
  opacity: 0.8;
}
.e-slidnav-img{
  width: 100%;
  height: 360px;
  border-radius: 8px;
  object-fit: contain;
  position: absolute;
}
.slick-dots{
  text-align: left !important;

}
.slick-dots li{
  margin: 0px -2px !important;
}
.slick-dots li button:before{
  font-size: 9px !important;

}
.e-productdetls-about-smallcase{
  padding-top: 100px;
}
.slick-next:before, .slick-prev:before{
  color: transparent !important;
}
.e-wrapper{
  width: 100%;
  height: 360px;
  position: relative;
}
.e-video-wrapp:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 1.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-video-thump{
  max-width:100%;
  height:auto;
  border-radius: 8px;
}

.e-modal-overlay{
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  transition: all ease;
  z-index: 20;

}
.e-wrapper iframe {
  display:none;
}
.e-play-btn {
  position:absolute;
  z-index:666;
  bottom: 125px;
  left: 300px;
  transform:translate(-50%, -50%);
  background-color:transparent;
  border:0;
}
/*.e-productdetls-about-smallcase .e-form-rule{
  top : 620px;
}*/
.e-prdtdtls-smallcase-h5{
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #444444;
}
.e-prdtdtls-p,.e-prdtdtls-usp p,.e-smallcase-bnft p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #444444;
}
/*.e-prdtdtls-usp p{
  font-size: 16px;
  line-height: 28px;
  color: black;
}*/
.e-prdtdtls-usp h6{
  font-weight: 600;
  color: #464646;
}
.e-box-span{
  background: #EBF4FF;
  border: 1px solid #0461D1;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: #0461D1;
  padding: 4px 5px;
  font-weight: 400;

}
.e-smallcase-bnft{
  padding: 78px 0px 80px 0px;
}
.e-smallcase-bnft .e-form-rule{
  top : 72%;
}
/*.e-prdtdtls-bold{
  font-weight: 800;
  color: #464646;
}*/
.e-prdtdtls-card{
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
  /*background-image: url("./images/prdtcardbgimg.svg");*/
  background-position: 0% 0%, 95% 6%;
  background-repeat: no-repeat;
}

.e-prdtdtls-card  .e-features-card{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}
.e-free-sticker{

}
.e-prdtdtls-card .e-dot{
  vertical-align: revert;
  font-weight: 700;
}
.e-prdtdtls-card .e-card-p{
  font-size: 14px;
  color: #444444;
}
.e-prdtdtls-ofr{
  margin-block-end: 5px;
}
.e-grn-span{
  color: #13D726;
  font-weight: 700;
}
.e-prdtdtls-bold .e-platform {
  font-weight: 700;
  color: #444444;
}
.e-prdtdtls-bold {
  font-weight: 800;
  color: #444444;
}
.e-bg-product {
  background: #FAFAFA;
  box-shadow: inset 0px 0px 2px #D9D9D9;
  border-radius: 4px;
}
.e-prdtdtls-card .e-primary-blue-btn, .e-prdtdtls-card .e-primary-grn-btn{
  padding: 15px 20px;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  width: 158px;
  display:inline-block;
  transition: ease-in-out 0.5s;
  margin-top: 10px;
}
.e-prdtdtls-card .e-primary-grn-btn{
  /*background-image: url("./images/btn-grn-arrow.svg");*/
  background-position: 95% 15%;
  background-repeat: no-repeat;
}
.e-prdtdtls-card .e-primary-blue-btn:hover{
  background-color: #2756A2;
}
.e-prdtdtls-card .e-primary-grn-btn:hover{
  background-color: #06BA2F;
}
.e-prdtdtls-card ul,.e-smallcase-bnft ul {
  padding-left: 17px;
}
.e-indent{
  margin-left: 10px;
}
.e-platform-card{
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}
.e-platform-card .e-form-rule{
  top: 12px;
  left: 30px;
}
.e-blue-p{
  color: #0461D1;
  font-size: 14px;
}
.e-platform-card p{
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}
.e-product-details .e-refer-card, .e-product-details .e-support{
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
}
.e-product-details .modal-content{
  height: 500px;
  min-width: 600px;
}
.e-refer-card .e-form-rule{
  top: 12px;
  left: 30px;
}
.e-earn-p{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333636;
  padding-right: 10px;
}
.e-refer-card .e-text{
  border-radius: 4px 0px 0px 4px;
  padding-right: 40px;
  border-right: none;
}
.e-refer-card .e-primary-blue-btn{
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  border-radius: 0px 4px 4px 0px;
  padding: 10px 28px;
}
.e-refer-card .e-primary-blue-btn[name="Clear"]{
  background-color: red;
}
.e-refer-card .e-primary-blue-btn[disabled]{
  opacity: 0.8;
}
.e-generated-head{
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444
}
.e-copy-btn{
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
  padding: 8px;
  text-align: center;
  border: 1px solid #0461D1;
  border-radius: 4px;
  width: 80px;
  background-color: #ffffff;
}
.e-earn-link{
  color: #0461D1 !important;
}
.e-copy-btn:focus{
  border: 1px solid #0461D1 !important;
}
.e-product-details .e-support p{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-block-end: 5px;
}
.e-support-link{
  font-size: 14px;
  line-height: 18px;
  color: #0461D1;
  letter-spacing: 0.05em;
  font-weight: 800;
  transition: ease-in-out 0.5s;

}
.e-support-img {
  height: 120px;
}
.e-support-link:hover{
  text-decoration: none;
  color: #0C3E90;
}
.e-support-link:hover .e-card-arrow{
  transform: translateX(5px);
  transition: ease-in-out 0.3s;
  color: #0C3E90;
}
.e-product_prev{
  background: white;
  border: none;
  font-weight: bold;
font-size: 14px;
line-height: 18px;
font-feature-settings: 'ss01' on, 'ss03' on;

color: #0461D1;
}
/*Market place details review tab*/

.e-rating-review {
  background: #FFFFFF;
border-radius: 4px;
}
progress {
  width: 60%;
  height: 5px;
  background: #FBCA1E;
}
progress::-webkit-progress-bar {
  background: #F2F2F2;;
}
progress::-webkit-progress-value {
  background: #FBCA1E;
}

.e-rview-pbar{
  font-size: 12px;
  line-height: 16px;
  color: #444444;
  margin-block-end: 10px;
}
.e-marketDetailsReview-sec{
  padding: 10px 0px 200px 0px;
}
.e-marketDetailsReview-sec h1{
  font-weight: 800;
  font-size: 36px;
  line-height: 2px;
  color: #444444;
  padding-bottom: 25px;
}
.e-ts-rating {
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}
.e-rview-no{
  font-size: 12px;
  line-height: 16px;
  font-weight: 200;
  color: #464646;
}
.e-marketDetailsReview-sec .css-1l6guga-control{
  border-radius: 8px !important;
  border-color: #F8F8F8;
  padding: 0;
}
.e-marketDetailsReview-sec  .css-peowp8-control{
  padding: 0 !important;
  border-radius: 8px !important;
  border-color: #F8F8F8;
}
.e-marketDetailsReview-sec .css-1uccc91-singleValue{
  color: #444444;
  font-size: 14px;
  line-height: 18px;
}
.e-marketDetailsReview-sec  .css-9gakcf-option{
  background-color: #ffffff;
  color: #0461D1;
}
.e-write-review{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #0461D1;
  transition: ease-in-out 0.5s;
}
.e-write-review:hover{
  text-decoration: none;
  color: #0C3E90;
  cursor: pointer;
}
/*review card*/
.e-review-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}
.e-reviewer{
  height: 40px;
}
.e-review-card-star{
  height: 12px;
}
.e-reviewer-name{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #444444;
}
.e-review-date{
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  color: #777676;
}
.e-review-content{
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  font-weight: 300;
  margin-block-end: 5px;
  height: 40px;
  overflow: hidden;

}
.e-more{
  overflow: auto;
}
.e-close{
  background: transparent;
  width: 30px;
}
.e-review-mark, .e-review-edit{
  color: #F85959;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  transition: ease 1s;
  border: none;
  background: none;
}
.e-review-edit{
  color: #0C3E90;
}
.e-review-mark{
  color: #F85959;
}
.e-review-mark:hover{
  text-decoration: none;
  color: #0C3E90;
}
.e-read-more{
  font-weight: 200;
  font-size: 14px;
  line-height: 24px;
  color: #0461D1!important;
  transition: ease-in-out 0.5s;
}
.e-read-more:hover{
  text-decoration: none;
  cursor: pointer;
  color: #2756A2;
}


/*review modal*/
.e-review-modal-closebtn{
  background-color: #ffffff;
}
.e-reviewmodal-head{
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  color: #444444
}
.e-reviewmodal-p{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}
.e-review-modal .e-text{
  resize: none;
  padding: 8px 8px 130px 8px;
}
.e-review-modal  input[type=radio]{
  display: none;
}
.e-review-modal .e-primary-blue-btn{
  padding: 15px 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  margin-top: 15px;
}
.e-review-modal .e-primary-blue-btn[disabled]{
  opacity: 0.5;
}
.accordion .btn.btn-link[aria-expanded="false"]{
  background-image: url("../images/accordionArrow.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;

}
.accordion .btn.btn-link[aria-expanded="true"]{
  background-image: url("../images/accordionup.svg");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;
}
.accordion .btn.btn-link{
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #444444;
  margin-left: -10px;
}
.accordion .btn:focus{
  box-shadow: none;
}
.accordion .btn-link{
  text-decoration: none;
}
.accordion .btn-link:hover, .accordion .btn-link:focus{
  text-decoration: none;
 }
 .e-accordion-head{
  border: none !important;
  background: #fcfcfc!important;
 }
 .accordion .card{
  border: none;
 }
 .accordion{
  border: none!important;
  margin-left: -45px;
 }
 .e-accordion-p{
  font-size: 16px;
  line-height: 24px;
  opacity: 0.87;
 }
 .accordion .card-body {
  background: #fcfcfc!important;
 }
 .e-accordion-col{
  position: absolute;
  width: 95%;
  top: 0;
  z-index: 10;
 }
 .e-col{
  position: relative;
 }
 .e-ac-open-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
 }
 .e-ac-open-card p{
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #464646;
 }
 .e-faq-gethelp{
  padding: 200px 0px;
 }
 .e-faq-gethelp h1{
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  color: #464646;
  font-feature-settings: 'ss03' on, 'ss01' on;
  padding: 10px 225px;
 }
 .e-faqbg-blue, .e-faqbg-orng, .e-faqbg-grn{
  position: absolute;
 }
.e-faqbg-blue{
  top: 1300px;
  left: 150px;
}
.e-faqbg-grn{
  top: 1100px;
  right: 150px;
}
.e-faqbg-orng{
  top: 1400px;
  right: 200px;
}
/*product request detail*/
.e-request-accept.e-product {
  padding: 8px 16px;
  background: #13D726;
  border-radius: 4px;
  color: #fff;
}
.e-request-reject.e-product {
  border: 1px solid #F85959;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;

}

/
/*payment tab content*/
.e-payment-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
  padding: 10px 20px 0px 20px;
}
.e-payment-card h5{
  font-weight: 800;
  font-size: 15px;
  color: #444444;
}
.e-payment-paid{
  font-size: 14px;
  line-height: 18px;
  color: black;
}
.e-payment-p{
  font-size: 12px;
  line-height: 16px;
  color: #444444;
}
.e-payment-card b{
  font-weight: 800;
}

.e-tab, .e-webinar-tab {
  overflow: hidden;
  border: none;
  /*background-color: white;*/
  padding: 20px 0px;
}

/* Style the buttons inside the tab */
.e-tab button {
  background-color: #fcfcfc;
  color: rgba(68, 68, 68, 0.67);
  font-weight: 700;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  padding: 0;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.e-tabbtn-active{
  color: #0461D1 !important;
  border-bottom: 2px solid #0461D1 !important;
}

.e-rating-star input[type="radio"]{
  display: none;
}
.e-star{
  font-size: 35px;
  padding-right: 10px;
}
.e-star:hover{
  cursor: pointer;
}
.e-pagination{
  list-style: none;
  margin: 0 auto;
}
.e-pagination li{
  display: inline-block;
}
.e-pagin-prev, .e-pagin-next{
  border: none;
  background-color: #ffffff;
}
.e-pagin-number{
  border: none;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #444444;

}
.e-pagin-active{
  background: #F0F0F0;
  border: 1px solid #0461D1 !important;
  padding: 3px 9px;
  border-radius: 50%;
}
.e-pagin-active a{
  background-color: #F0F0F0;
}
.input-range__track--active, .input-range__slider{
  background-color: #0461D1 !important;

}
.input-range {
  width: 250px;
  margin: 30px 0px;

}
.input-range__slider{
  height: 12px;
  width: 12px;
  margin-top: -8px;
}
.input-range__track--active, .input-range__track--background{
  height: 2px;
}
.input-range__track--background{
  background-color: #D5D5D5 !important;

}
.input-range__label--max, .input-range__label--min{
  display: none;
}
.input-range__label--value{
  top: 10px;
  color: #444444;
}
.e-video-modal{
  top: 100px !important;
  left: 300px !important;
  display: block !important;
  width: 690.84px !important;
  height: 396px !important;
}
.e-videomodal-close{
  top: 0;
  right: 0;
  background: white;
  border: none;
  padding: 10px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 16px 32px rgba(0, 0, 2, 0);
  z-index: 2;
}
.e-review-modal{
  top: 80px !important;
  left: 400px !important;
  display: none;
  background-color: #ffffff;
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 445px !important;
  height: 460px !important;
}
.e-modal-show{
  display: block !important;
}
/*confirmation modal*/
.e-confirmation {
  background-image: url("../images/delete-product.svg");
  background-size: 26px !important;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 25px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 5px;

}

/*resources*/
/*.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}*/

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  z-index: 1;
  right: 0;
  border-radius: 10px;
  /*top: 35px;*/
  top: 25px;
  padding: 4px;
}

.dropdown-content span {
  color: #444444;
  padding: 15px 5px 5px 5px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.e-nav-item-wrap .e-nav-item.e-active:hover {
  color: #0461D1;
}

/*.dropdown-content a:hover {background-color: #ddd;}*/

.dropdown:hover .dropdown-content {
  display: block;
}

/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/

.e-add-video-img {
  position: relative;
  top: -1px;
  left: -7px;
}
.e-select-product .css-2b097c-container {
    border-radius: 4px;
    line-height: 0px;
    font-weight: 400;
    border: 0;
    background: #fff;
    border: 1px solid #EAEAEA;
    padding: 5px 0px!important;
}
.e-select-product .css-yk16xz-control {
  box-shadow: none!important;
}
.e-select-product .css-1wa3eu0-placeholder {
  color: #999999!important;
  font-size: 14px!important;
}
/*.e-video-card .card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}
.embed-responsive {
  border-radius: 8px 8px 0px 0px;
}
.e-video-card .card-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
  min-height: 38px;
}*/

/*resourse page vedio tab*/
.e-video-card-head{
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #444444;
  min-height: 48px;
}
.e-card-hover{
  transition: ease-in-out 0.4s;
}
.e-card-hover:hover{
  transform: translateY(-3px) !important;
  cursor: pointer;
}
.e-videolist-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px !important;
}
.e-video-card-headersec{
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-vidoelist-play-btn{
  position:absolute;
  z-index:666;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  background-color:transparent;
  border:0;
}
.e-vidoelist-play{
  width: 50px;
  position: relative;
}
.e-vidoe-duration{
  position:absolute;
  z-index:666;
  left: 30px;
  bottom: 100px;
  transform:translate(-50%, -50%);
  background-color:transparent;
  border:0;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}
#video-cover {
  width: 100%;
}

.e-vidoe-list-span{
  padding: 5px 10px;
  background: #EBF4FF;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #0461D1;
}
/*webinar list*/
.e-webinar-card-headersec:after{
  content: '';
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  height: 39%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-webinar-tab{
  background: #fcfcfc;
}
.e-webinar-p{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-webinar-span{
  font-size: 12px;
  line-height: 16px;
  color: #444444;
  opacity: 0.8;
}
.e-webinar .e-primary-blue-btn{
  padding: 11px 20px;
  font-weight: bold;
  font-size: 13px;
  line-height: 17px;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
  background-color: #0461D1;
  border: none;
  border-radius: 8px;
}
.e-videolist-thumb{
  border-radius: 8px 8px 0px 0px;
  max-height: 156px;
  object-fit: cover;
}
.e-webinar-schedule{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  position: absolute;
  top: 130px;
  left: 10px;
  z-index: 2;
}
.e-webinar-videocam {
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 2;
}
.e-webinar .e-primary-blue-btn:hover{
  background: #2756A2;
}
.e-webinar .e-vidoelist-play-btn{
  bottom: 235px;
}
.e-webinar  .e-vidoe-duration{
  bottom: 207px;
  left: 75px;
}

.e-video-delete.e-webinar {
  top:34%;
}


/*---blog----*/
.e-videolist-card.e-blog .e-vidoelist-play {
  top: -96px;

}
.e-videolist-card.e-blog .e-video-delete {
  top: 31%;
}
.e-blog-read-more {

}
/*perfomance report*/
.e-report-card {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 8px;
}
.e-report-head{
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #444444;
  width: 150px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-pdf-download{
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #0461D1;
}
.e-report-delete{
  background: transparent;
  border: none;
}
.e-report-query-sec{
  padding: 400px 0px 200px 0px;
}
.e-report-query-head{
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #444444;
}
.e-upload-doc {
  font-size: 13px;
  border-radius: 5px;
  border: 2px dashed #ccc;
  padding: 15px 17px;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  background-color:  #F6FBFF;
  font-weight: 600;
  color: #999999;
  width: 100%;
}
.e-img-preview{
  position: relative;
  display: inline-block;
  background: #f1f1f1;
  width: 100%;
  height: 135px;
  border-radius: 7px;
}
.e-img-preview img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.e-img-preview .e-change{
  background-image: url("../images/img-preview-close.svg");
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
}
.e-img-preview .e-change:hover{
  transform: scale(1.025)
}
.e-img-upload-loader{
  width: 100%;
  height: 134px;
  background-image: url("../images/upload-loader.gif");
  background-position: center;
  background-size: 20px;
  display: block;
  background-repeat: no-repeat;
  border: 2px dashed #ccc;
  border-radius: 5px;
}

/*Resource video details*/
.e-resource-video-cover{
  position: relative;
  z-index: 2;
  width: 100%;
  max-height: 500px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
}
.e-resource-video{
  min-height: 480px;
}
.e-video-thump:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-resouce-video-durtn{
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: #ffffff;
    z-index: 4;
}
.e-videdtls .e-blog-delete, .e-videdtls .e-blog-edit{
  bottom: 50px;
  z-index: 2;
}
.e-video-del{
  right: 100px !important;
}
.e-videdtls .e-blog-edit{
  left: 20px;
}
.e-video-edit, .e-video-delete{
  position: absolute;
  background-color: transparent;
  border: none;
  bottom: 20px;
  z-index: 4;
}
.e-video-edit{
  right: 30px;
}
.e-video-delete{
  right: 60px;
}
/*resource blog details*/
.e-blog-details-header{
  background: #0461D1;
  padding: 40px 0px 300px 0px;
}
.e-blog-details-header .e-marketDetails-link{
  color: #ffffff;
  opacity: 1;
  transition: ease-in-out 0.5s;
}
.e-blog-details-header .e-marketDetails-link-color{
  color: #ffffff;
  opacity: 0.5;
}
.e-blog-details-header .e-marketDetails-link:hover{
  color: #1A1B1B;
}
.e-blogdtls-span{
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 5px 10px;
  border-radius: 100px;
}
.e-blogdtls-time{
  color: #FFFFFF;
  font-weight: 400;
}
.e-blog-content-fixed, .e-blog-fixed-auth{
  width:200px;
  z-index: 2;
    margin-right:5px;
  min-height:250px;
  position:fixed;
  top: 10px;
}

.e-blog-fixed-auth{
  right: 0;
}
.e-blog-content-fixed{
  float: left;
}
.e-blog-scroll{
  position: absolute;
  left: 24%;
  width: 700px;
}
.e-blogdtls-p, .e-blogdtls-bgcolor-p{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
}
.e-blogdtls-percentage{
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
}
.e-blogdtls-bgcolor-p{
  border-radius: 4px;
  background: #E4F0FF;
  padding: 5px 2px 5px 6px;
}
.e-blog-author{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
}
.e-bio{
  min-height: 95px !important;
}
.e-resource-dtls-head{
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #ffffff;
  opacity: 0.7;
  vertical-align: middle
}
.e-blogcover-img{
  position: absolute;
  max-height: 320px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}
.e-blog-thump:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-blogcover-h1{
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  color: #FFFFFF;
}
.e-blog-complete{
  width: 100%;
  height: 2px;
}
.e-blog-about progress[value]::-webkit-progress-value{
  background-color: #0461D1 !important;
}
.e-blog-about{
  margin-left: 750px;
}
.e-blog-author-img{
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 100px;
}
.e-blog-edit, .e-blog-delete{
  position: absolute;
  background-color: transparent;
  border: none;
  bottom: -300px;
}
.e-blog-delete{
  right: 50px;
}
.e-blog-edit{
  right: 10px;
}
.e-add-blog-sec-head{
  background: #CFD7D7;
}
/*webinar details*/
.e-edit-head{
  font-size: 24px;
  font-weight: 700;
}
.e-webinar-time{
  position: absolute;
  color: #FFFFFF;
  left: 430px;
  bottom: 200px;
  z-index: 4;
}
.DateInput_input{
  line-height: 0px;
  font-size: 14px!important;
  font-weight: 500 !important;
}
.e-webinar-dtls .e-blog-edit, .e-webinar-dtls .e-blog-delete{
  bottom: 10px;
  z-index: 4;
}
.e-webinar-thump:after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: inline-block;
  background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  border-radius: 8px 8px 0px 0px !important;
}
.e-webinar-dtls .e-blog-edit{
  left: 20px;
}
/*.css-tj5bde-Svg{
  background-image: url('../images/selectArrow/selectdownarrow.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  fill: transparent !important;
}*/
/*toast*/
.e-error{
  background-color: #F5C0C0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #D40000;
  font-weight: 500;
  border: 1px solid #D40000;
  text-transform: capitalize;
}
.e-toast{
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 99;
}
.e-toast.success{
  background-image: url('../images/icons/check.svg');
  border-color: #68D868;
  background-color: #E9FFE0;
}
.e-toast.fail{
  background-image: url('../images/icons/cancel.svg');
  border-color: red;
  background-color: #F9E5E5;
}
/*.e-upload-btn:hover {
    color: #0461D1;
    background-image: url("../images/upload-logo.svg");
    border: 2px dashed #0461D1;
    background-color: #f9f9f9;
}
*/
/*profile */
.e-profile-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.62);
  border-radius: 8px;
  padding: 15px;
}
.e-profile-head{
  font-weight: 800;
  color: #444444;
}
.e-profile-phone{
  margin-block-end: 0px;
}
.e-profile-card p{
  font-size: 14px;
  color: #444444;
}
.e-paid-card{
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on, 'ss03' on, 'liga' off;
  font-weight: 400;
  color: #444444;
}
.e-card-amount{
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss03' on, 'ss01' on;
  color: #444444
}
.e-profile-card .e-header-shortname{
  padding: 12px;
}
.e-profile-edit{
  border: none;
  background: white;
  color: blue;
  font-weight: 600;
  margin-left: 300px
}
.e-bank-closebtn{
  border: none;
  background: white;
  margin-left: 10px;
}
.e-edit-bank .e-primary-blue-btn{
  background: #0461D1;
  border-radius: 4px;
  border: none;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: 400;
  transition: ease-in-out 0.5s;
}
.e-edit-bank .e-primary-blue-btn:hover{
  background: #0D31B1 ;
}
.table thead{
  background-color: #f2f2f2;
}
.table  tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.table  tbody td{
  padding-top:20px;
  padding-bottom:20px;
  padding-right:20px;
}
.e-green{
  color: #13D726;
}
.e-accesstoken{
  width: 100%;
  word-wrap: break-word;
  font-size: 13px;
  font-weight: 600;
}
.e-api-details-modal .modal-dialog{
  max-width: 800px;

}
.e-link-copied{
  display: inline-block;
  font-size: 13px;
  padding: 4px 8px;
  background: #d4f5d9;
  color: green;
  font-weight: 500;
  border-radius: 5px;
}
.e-add-bank-details-btn{
  margin-bottom: 20px;
}

.e-enable-hook-modal .modal-footer{
  border: none;
}
.e-login-link {
  color: #0461D1!important;
  text-decoration: underline!important;
}
.e-login-link:hover {
  opacity: 0.8;
}